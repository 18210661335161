import React, { useState, useEffect } from 'react';
//import logo from './logo.png';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import { withRouter } from 'react-router'
// import Home from './component/Home';
import "../node_modules/video-react/dist/video-react.css";
// import TagManager from 'react-gtm-module'
import initFacebookSdk from './component/dashboard-new/components/facebook-sdk'
import initInstagramSdk from './component/dashboard-new/components/instagram-sdk'
import ChangePassword from './component/ChangePassword'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

//import TheLayout from './component/admin/containers/TheLayout'
import {
	Switch,
	Route,
} from "react-router-dom";

import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/dropdown'

import DashboardLogIn from './component/LogIn';
import ResetPassword from './component/ReasetPassword';
import SignUp from './component/SignUp';


import MetaTags from 'react-meta-tags';
import { AuthProvider } from './context/AuthContext';
// import { createContext, useContext } from 'react';

// const LocalStorageContext = createContext();
// const AdminNew = localStorage.getItem('accessNewToken')

// const parseJwt = (token) => {
// 	return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
// };

// console.log("AdminNew", parseJwt(AdminNew))
// const idVariable = parseJwt(AdminNew).id;

// localStorage.setItem('accessNewTokenId', idVariable)
// console.log('ID using dot notation:', idVariable);




const ClientLayout = React.lazy(() => import('./component/containers/ClientLayout'));

const SiteLayout = React.lazy(() => import('./component/containers/TheLayout'));

// const TheLayout = React.lazy(() => import('./component/admin/containers/TheLayout'));


const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)

const runSDK = (channelName) => {
	if (channelName === "Facebook") {
		initFacebookSdk().then(App)
	} else if (channelName === "Instagram") {
		initInstagramSdk().then(App)
	} else {
		initFacebookSdk().then(App)
	}
}

// initInstagramSdk().then(App)

function App(props) {
	const [title, SetTitle] = useState();
	const [description, SetDescription] = useState();
	const [keywords, SetKeywords] = useState();
	const [canonical, SetCanonical] = useState();
	const [ogtitle, SetOgTitle] = useState();
	const [ogsite_name, SetOgSite_name] = useState();
	const [ogurl, SetOgUrl] = useState();
	const [ogdescription, SetOgDescription] = useState();
	const [ogtype, SetOgType] = useState();
	const [ogimage, SetOgImage] = useState();
	const [twittercard, SetTwitterCard] = useState();
	const [twittersite, SetTwitterSite] = useState();
	const [twittertitle, SetTwitterTitle] = useState();
	const [twitterdescription, SetTwitterDescription] = useState();
	const [twitterimage, SetTwitterImage] = useState();

	useEffect(() => {
		runSDK(localStorage.getItem('media'))
	}, [localStorage.getItem('media')]);

	useEffect(() => {
		metas();
	})

	const metas = () => {
		switch (props.location.pathname) {
			case '/':
				SetTitle('Growth Marketing Company India | Ezio Solutions Pvt Ltd')
				SetDescription("Subscribe before 31st March 2021 & Pay only 50% for each Services. Ezio Solutions Coimbatore, India provides SEO, Social Media, PPC, Website Development, ASO, Branding services globally")
				SetKeywords("Growth Hacking Services, Growth Marketing Consultant, Ezio Solutions, Digital Marketing with Growth Hacking, Coimbatore's Marketing Agency,SEO Company In Coimbatore,Digital Marketing Company In Coimbatore,Growth Hacking Agency,Best Digital Marketing Company In Coimbatore,Digital Marketing Agency In Coimbatore")
				SetCanonical('https://www.biz.eziosolutions.com')
				SetOgTitle('Growth Marketing Company India | Ezio Solutions Pvt Ltd')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com')
				SetOgDescription('Subscribe before 31st March 2021 & Pay only 50% for each Services. Ezio Solutions Coimbatore, India provides SEO, Social Media, PPC, Website Development, ASO, Branding services globally')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Growth Marketing Company India | Ezio Solutions Pvt Ltd')
				SetTwitterDescription('Subscribe before 31st March 2021 & Pay only 50% for each Services. Ezio Solutions Coimbatore, India provides SEO, Social Media, PPC, Website Development, ASO, Branding services globally')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/about-us':
				SetTitle('DIgital Marketing Agency | About - Ezio Solutions')
				SetDescription("Ezio Solutions is a global leader in providing next-gen services like Growth Marketing with Digital Marketing (SEO, PPC, Social Media), Email Marketing, ECommerce Product Listing, Web Designing & Development. Know more.")
				SetKeywords("Ezio Solutions Coimbatore, Growth Marketing Company, Marketing Agency, Web development services, ECommerce Product Management Services")
				SetCanonical('https://www.eziosolutions.com/about-us')
				SetOgTitle('DIgital Marketing Agency | About - Ezio Solutions')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/about-us')
				SetOgDescription('Ezio Solutions is a global leader in providing next-gen services like Growth Marketing with Digital Marketing (SEO, PPC, Social Media), Email Marketing, ECommerce Product Listing, Web Designing & Development. Know more.')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('DIgital Marketing Agency | About - Ezio Solutions')
				SetTwitterDescription('Ezio Solutions is a global leader in providing next-gen services like Growth Marketing with Digital Marketing (SEO, PPC, Social Media), Email Marketing, ECommerce Product Listing, Web Designing & Development. Know more.')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/seo':
				SetTitle('SEO Services | Search Engine Optimization Company - Ezio Solutions')
				SetKeywords("seo company in coimbatore, seo services in coimbatore,best seo company in coimbatore,local seo services,seo packages,seo agency,seo expert,seo consultant")
				SetDescription("We provide the best SEO Services that help you to improve your organic ranking. Get your affordable SEO packages @ EZIO Solutions Pvt Ltd, Coimbatore, India.  Top SEO Services in Coimbatore")
				SetCanonical('https://www.eziosolutions.com/seo')
				SetOgTitle('SEO Services | Search Engine Optimization Company - Ezio Solutions')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/seo')
				SetOgDescription('We provide the best SEO Services that help you to improve your organic ranking. Get your affordable SEO packages @ EZIO Solutions Pvt Ltd, Coimbatore, India.  Top SEO Services in Coimbatore')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('SEO Services | Search Engine Optimization Company - Ezio Solutions')
				SetTwitterDescription('We provide the best SEO Services that help you to improve your organic ranking. Get your affordable SEO packages @ EZIO Solutions Pvt Ltd, Coimbatore, India.  Top SEO Services in Coimbatore')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/smo':
				SetTitle('SMO Services | Social Media Marketing Company - Ezio Solutions India')
				SetKeywords("Social media optimization, SMO services, Facebook digital marketing, Twitter marketing company, Linkedin marketing agency, Youtube video promotion services, Pinterest marketing, Snapchat marketing services, SMO strategy")
				SetDescription("Build brand presence in social media like facebook, twitter, linkedin & youtube. Get pricing to grow business with our smo services. Ezio Solutions Pvt Ltd, Coimbatore")
				SetCanonical('https://www.eziosolutions.com/smo')
				SetOgTitle('SMO Services | Social Media Marketing Company - Ezio Solutions India')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/smo')
				SetOgDescription('Build brand presence in social media like facebook, twitter, linkedin & youtube. Get pricing to grow business with our smo services. Ezio Solutions Pvt Ltd, Coimbatore')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('SMO Services | Social Media Marketing Company - Ezio Solutions India')
				SetTwitterDescription('Build brand presence in social media like facebook, twitter, linkedin & youtube. Get pricing to grow business with our smo services. Ezio Solutions Pvt Ltd, Coimbatore')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/ppc':
				SetTitle('PPC Managment Services | PPC Advertising Company - Ezio Solutions')
				SetKeywords("ppc advertising,google adwords expert,ppc expert,google ppc,ppc marketing")
				SetDescription("We provide the best ROI investments for your PPC Campaigns. Get free consultation with our PPC experts @ EZIO Solutions Pvt ltd Coimbatore, India")
				SetCanonical('https://www.eziosolutions.com/ppc')
				SetOgTitle('PPC Managment Services | PPC Advertising Company - Ezio Solutions')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/ppc')
				SetOgDescription('We provide the best ROI investments for your PPC Campaigns. Get free consultation with our PPC experts @ EZIO Solutions Pvt ltd Coimbatore, India')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('PPC Managment Services | PPC Advertising Company - Ezio Solutions')
				SetTwitterDescription('We provide the best ROI investments for your PPC Campaigns. Get free consultation with our PPC experts @ EZIO Solutions Pvt ltd Coimbatore, India')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/fb-advertising':
				SetTitle('Facebook Advertising & Marketing Agency | SMM Services')
				SetKeywords("Social Media Marketing Company, Social Media Marketing Agency, social media marketing services, Facebook Marketing Company, Social Media Marketing Company India, Facebook Ad Agency, Social Media Marketing Consultant")
				SetDescription("Being a leading Marketing agency, we do strong, results-driven Facebook Advertising and SMM services to improve brand visibility and bring traffic to your website.")
				SetCanonical('https://www.eziosolutions.com/fb-advertising')
				SetOgTitle('Facebook Advertising & Marketing Agency | SMM Services')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/fb-advertising')
				SetOgDescription('Being a leading Marketing agency, we do strong, results-driven Facebook Advertising and SMM services to improve brand visibility and bring traffic to your website.')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Facebook Advertising & Marketing Agency | SMM Services')
				SetTwitterDescription('Being a leading Marketing agency, we do strong, results-driven Facebook Advertising and SMM services to improve brand visibility and bring traffic to your website.')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/aso':
				SetTitle('App Store Optimization Services | Mobile App Marketing Agency')
				SetKeywords("ASO Agency, App Promotion Company, APP Advertising, App Advertising Companies")
				SetDescription("Ezio Solutions helps you to promote the mobile app, We provide the best app store optimization services. We are the best mobile app marketing company in india")
				SetCanonical('https://www.eziosolutions.com/aso')
				SetOgTitle('App Store Optimization Services | Mobile App Marketing Agency')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/aso')
				SetOgDescription('Ezio Solutions helps you to promote the mobile app, We provide the best app store optimization services. We are the best mobile app marketing company in india')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('App Store Optimization Services | Mobile App Marketing Agency')
				SetTwitterDescription('Ezio Solutions helps you to promote the mobile app, We provide the best app store optimization services. We are the best mobile app marketing company in india')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/native-advertising':
				SetTitle('DIgital Marketing Agency | About - Ezio Solutions')
				SetKeywords("Native Advertising, Native Advertising Services, Native Advertising Companies, Native Ads Company, Best Native Advertising, Native Ads Agency")
				SetDescription("Native Ads are a form of paid advertisement. As a native advertising agency, we help you to set up campaigns, and it help you to increase your brand reach")
				SetCanonical('https://www.eziosolutions.com/native-advertising')
				SetOgTitle('DIgital Marketing Agency | About - Ezio Solutions')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/native-advertising')
				SetOgDescription('Native Ads are a form of paid advertisement. As a native advertising agency, we help you to set up campaigns, and it help you to increase your brand reach')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('DIgital Marketing Agency | About - Ezio Solutions')
				SetTwitterDescription('Native Ads are a form of paid advertisement. As a native advertising agency, we help you to set up campaigns, and it help you to increase your brand reach')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/affiliate-marketing':
				SetTitle('Affiliate Marketing Companies | Affiliate Marketing Services')
				SetKeywords("Affiliate Marketing, Affiliate Marketing Agency, Best Affiliate Marketing, Best Affiliate Marketing Companies, Best Affiliate Companies")
				SetDescription("We offer Affiliate marketing that will increase lead generation and sales. Unlike other Affiliate marketing companies, we do the best Affiliate marketing here.")
				SetCanonical('https://www.eziosolutions.com/affiliate-marketing')
				SetOgTitle('Affiliate Marketing Companies | Affiliate Marketing Services')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/affiliate-marketing')
				SetOgDescription('We offer Affiliate marketing that will increase lead generation and sales. Unlike other Affiliate marketing companies, we do the best Affiliate marketing here.')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Affiliate Marketing Companies | Affiliate Marketing Services')
				SetTwitterDescription('We offer Affiliate marketing that will increase lead generation and sales. Unlike other Affiliate marketing companies, we do the best Affiliate marketing here.')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/email-marketing':
				SetTitle('Email Marketing Services | Email Automation Agency - Ezio Solutions India')
				SetKeywords("Email marketing services, Email marketing automation, Email template, Newsletter automation, Email campaign, Drip email campaign")
				SetDescription("Nurture your subscribers, engage the leads through our email automation campaigns.Ezio Solutions Pvt Ltd, Coimbatore for your Email Marketing Support")
				SetCanonical('https://www.eziosolutions.com/email-marketing')
				SetOgTitle('Email Marketing Services | Email Automation Agency - Ezio Solutions India')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/email-marketing')
				SetOgDescription('Nurture your subscribers, engage the leads through our email automation campaigns.Ezio Solutions Pvt Ltd, Coimbatore for your Email Marketing Support')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Email Marketing Services | Email Automation Agency - Ezio Solutions India')
				SetTwitterDescription('Nurture your subscribers, engage the leads through our email automation campaigns.Ezio Solutions Pvt Ltd, Coimbatore for your Email Marketing Support')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/web-development':
				SetTitle('Web Development Company | E-Commerce Website Development Company')
				SetKeywords("Website Design Company ,Website Development Company,Web Design Agency,Web Design Services ,E-Commerce Website Design,Web Design and Development")
				SetDescription("We are the best Website Development Company. We develop, Wordpress website, E-Commerce website, and more. We are EZIO Solutions Private Limited - Coimbatore")
				SetCanonical('https://www.eziosolutions.com/web-development')
				SetOgTitle('Web Development Company | E-Commerce Website Development Company')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/web-development')
				SetOgDescription('We are the best Website Development Company. We develop, Wordpress website, E-Commerce website, and more. We are EZIO Solutions Private Limited - Coimbatore')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Web Development Company | E-Commerce Website Development Company')
				SetTwitterDescription('We are the best Website Development Company. We develop, Wordpress website, E-Commerce website, and more. We are EZIO Solutions Private Limited - Coimbatore')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/ui-ux-design':
				SetTitle('Graphic Design Companies | UI/UX Design Agency - Ezio India')
				SetKeywords("UX Design Companies, Graphic Design Agency, Graphic Design Studio, UX Design Agency, Graphic Design Business")
				SetDescription("Ezio Solutions Pvt Ltd best graphics design company. We provide UI/UX design for website and mobile app development. The most creative UI/UX Design agency ")
				SetCanonical('https://www.eziosolutions.com/ui-ux-design')
				SetOgTitle('Graphic Design Companies | UI/UX Design Agency - Ezio India')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/ui-ux-design')
				SetOgDescription('Ezio Solutions Pvt Ltd best graphics design company. We provide UI/UX design for website and mobile app development. The most creative UI/UX Design agency ')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Graphic Design Companies | UI/UX Design Agency - Ezio India')
				SetTwitterDescription('Ezio Solutions Pvt Ltd best graphics design company. We provide UI/UX design for website and mobile app development. The most creative UI/UX Design agency ')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/logo-designing':
				SetTitle('Logo Designing Company | Logo Designing Company In India')
				SetKeywords("Logo for interior Design Company, Logo Designing Company in Coimbatore, Logo Designing Company in India , Logo Designing Company Delhi logo design best company")
				SetDescription("Ezio Solutions is the best logo designing company. We have the creative team to design logo, Broucher, flyers, business card, and Website design")
				SetCanonical('https://www.eziosolutions.com/logo-designing')
				SetOgTitle('Logo Designing Company | Logo Designing Company In India')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/logo-designing')
				SetOgDescription('Ezio Solutions is the best logo designing company. We have the creative team to design logo, Broucher, flyers, business card, and Website design')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Logo Designing Company | Logo Designing Company In India')
				SetTwitterDescription('Ezio Solutions is the best logo designing company. We have the creative team to design logo, Broucher, flyers, business card, and Website design')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/business-verticals':
				SetTitle('Digital Marketing Agency - Business Verticals')
				SetKeywords("Ezio Solutions Coimbatore, Growth Marketing Company, Marketing Agency, Web development services, ECommerce Product Management Services")
				SetDescription("Ezio solutions- The leading Digital marketing agency, create innovative strategies to offer business growth to customers from various Industry verticals.")
				SetCanonical('https://www.eziosolutions.com/business-verticals')
				SetOgTitle('Digital Marketing Agency - Business Verticals')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/business-verticals')
				SetOgDescription('Ezio solutions- The leading Digital marketing agency, create innovative strategies to offer business growth to customers from various Industry verticals.')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Digital Marketing Agency - Business Verticals')
				SetTwitterDescription('Ezio solutions- The leading Digital marketing agency, create innovative strategies to offer business growth to customers from various Industry verticals.')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/how-we-work':
				SetTitle('How we  Work - EZIO Solutions [P] Ltd')
				SetKeywords("SEO Company In Coimbatore,Digital Marketing Company In Coimbatore,Growth Hacking Agency,Best Digital Marketing Company In Coimbatore,Digital Marketing Agency In Coimbatore")
				SetDescription("EZIO Solutions Pvt Ltd a leading Digital Marketing Agency in Coimbatore. We Offer SEO Services, SMO Services,E-Mail Marketing,Bulk SMS and Web Design and development")
				SetCanonical('https://www.eziosolutions.com/how-we-work')
				SetOgTitle('How we  Work - EZIO Solutions [P] Ltd')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/how-we-work')
				SetOgDescription('EZIO Solutions Pvt Ltd a leading Digital Marketing Agency in Coimbatore. We Offer SEO Services, SMO Services,E-Mail Marketing,Bulk SMS and Web Design and development')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('How we  Work - EZIO Solutions [P] Ltd')
				SetTwitterDescription('EZIO Solutions Pvt Ltd a leading Digital Marketing Agency in Coimbatore. We Offer SEO Services, SMO Services,E-Mail Marketing,Bulk SMS and Web Design and development')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/b2b-membership':
				SetTitle('B2B Membership Program - Ezio Solutions Pvt Ltd')
				SetKeywords("Ezio Solutions Coimbatore, Growth Marketing Company, Marketing Agency, Web development services, ECommerce Product Management ServicesB2B Membership Program - Ezio Solutions Pvt Ltd")
				SetDescription("Ezio Solutions Pvt Ltd offer B2B membership program for businesses firms to help them increase their sales growth, and capture customers, in a long run. ")
				SetCanonical('https://www.eziosolutions.com/b2b-membership')
				SetOgTitle('B2B Membership Program - Ezio Solutions Pvt Ltd')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/b2b-membership')
				SetOgDescription('Ezio Solutions Pvt Ltd offer B2B membership program for businesses firms to help them increase their sales growth, and capture customers, in a long run. ')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('B2B Membership Program - Ezio Solutions Pvt Ltd')
				SetTwitterDescription('Ezio Solutions Pvt Ltd offer B2B membership program for businesses firms to help them increase their sales growth, and capture customers, in a long run. ')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/college-membership':
				SetTitle('College Membership Program - Ezio Solutions Private Limited')
				SetKeywords("Ezio Solutions Coimbatore, Growth Marketing Company, Marketing Agency, Web development services, ECommerce Product Management Services")
				SetDescription("Ezio Solutions Pvt Ltd offer B2B membership program for businesses firms to help them increase their sales growth, and capture customers, in a long run.")
				SetCanonical('https://www.eziosolutions.com/college-membership')
				SetOgTitle('College Membership Program - Ezio Solutions Private Limited')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/college-membership')
				SetOgDescription('Ezio Solutions Pvt Ltd offer B2B membership program for businesses firms to help them increase their sales growth, and capture customers, in a long run. ')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('College Membership Program - Ezio Solutions Private Limited')
				SetTwitterDescription('Ezio Solutions Pvt Ltd offer B2B membership program for businesses firms to help them increase their sales growth, and capture customers, in a long run. ')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/blog':
				SetTitle('Blogs | Google Recent Updates | Social Media News - Ezio ')
				SetKeywords("Ezio Solutions Coimbatore, Growth Marketing Company, Marketing Agency, Web development services, ECommerce Product Management Services")
				SetDescription("Stay ahead of the marketing competition by keeping yourself updated with our blogs on Social media news-Ezio, Google recent updates, and other digital updates.Stay ahead of the marketing competition by keeping yourself updated with our blogs on Social media news-Ezio, Google recent updates, and other digital updates. ")
				SetCanonical('https://www.eziosolutions.com/blog')
				SetOgTitle('Blogs | Google Recent Updates | Social Media News - Ezio ')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/blog')
				SetOgDescription('Stay ahead of the marketing competition by keeping yourself updated with our blogs on Social media news-Ezio, Google recent updates, and other digital updates. ')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Blogs | Google Recent Updates | Social Media News - Ezio College Membership Program - Ezio Solutions Private Limited')
				SetTwitterDescription('Stay ahead of the marketing competition by keeping yourself updated with our blogs on Social media news-Ezio, Google recent updates, and other digital updates. ')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			case '/blog/how-businesses-can-conquer-this-covid-19-crisis':
				SetTitle('Covid-19 - How businesses can conquer this pandemic crisis?')
				SetKeywords("Social Media Optimization, SMO Services, Facebook Digital Marketing, Twitter Marketing Company, Linkedin Marketing Agency, Youtube Video Promotion Services, Pinterest Marketing, Snapchat Marketing Services, SMO Strategy")
				SetDescription("No doubt a lot of work culture has been changed since the breakdown of this pandemic. Certain industries and communities got severely down due to this uncontrollable spread.")
				SetCanonical('https://www.eziosolutions.com/blog/how-businesses-can-conquer-this-covid-19-crisis')
				SetOgTitle('Covid-19 - How businesses can conquer this pandemic crisis?No doubt a lot of work culture has been changed since the breakdown of this pandemic. Certain industries and communities got severely down due to this uncontrollable spread.')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/blog/how-businesses-can-conquer-this-covid-19-crisis')
				SetOgDescription('No doubt a lot of work culture has been changed since the breakdown of this pandemic. Certain industries and communities got severely down due to this uncontrollable spread.')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/assets/img/covid-blog-main.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Covid-19 - How businesses can conquer this pandemic crisis?')
				SetTwitterDescription('No doubt a lot of work culture has been changed since the breakdown of this pandemic. Certain industries and communities got severely down due to this uncontrollable spread.')
				SetTwitterImage('https://www.eziosolutions.com/assets/img/blog-detail-image.png')
				break;
			case '/blog/how-to-recommence-your-business-after-lockdown':
				SetTitle('How to Recommence Your Business After Lockdown')
				SetKeywords("Social Media Optimization, SMO Services, Facebook Digital Marketing, Twitter Marketing Company, Linkedin Marketing Agency, Youtube Video Promotion Services, Pinterest Marketing, Snapchat Marketing Services, SMO Strategy")
				SetDescription("Everything happened at a drastic speed of time! We were completely living a different life before this pandemic, and currently into a living that we never dreamt of.")
				SetCanonical('https://www.eziosolutions.com/blog/how-to-recommence-your-business-after-lockdown')
				SetOgTitle('How to Recommence Your Business After Lockdown')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/blog/how-to-recommence-your-business-after-lockdown')
				SetOgDescription('Everything happened at a drastic speed of time! We were completely living a different life before this pandemic, and currently into a living that we never dreamt of.')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/assets/img/business-after-lockdown-page.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('How to Recommence Your Business After Lockdown')
				SetTwitterDescription('Everything happened at a drastic speed of time! We were completely living a different life before this pandemic, and currently into a living that we never dreamt of.')
				SetTwitterImage('https://www.eziosolutions.com/assets/img/business-after-lockdown-page.png')
				break;
			case '/blog/impact-of-growth-marketing-in-businesses':
				SetTitle('How Growth Marketing Impact The Businesses In 2020')
				SetKeywords("Social Media Optimization, SMO Services, Facebook Digital Marketing, Twitter Marketing Company, Linkedin Marketing Agency, Youtube Video Promotion Services, Pinterest Marketing, Snapchat Marketing Services, SMO Strategy")
				SetDescription("Over the last few years, the term growth marketing has attained wider popularity and undoubtedly gained a heightened growth. Well, many of us might be pretty familiar with the term but not the concept")
				SetCanonical('https://www.eziosolutions.com/blog/impact-of-growth-marketing-in-businesses')
				SetOgTitle('How Growth Marketing Impact The Businesses In 2020')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/blog/impact-of-growth-marketing-in-businesses')
				SetOgDescription('Over the last few years, the term growth marketing has attained wider popularity and undoubtedly gained a heightened growth. Well, many of us might be pretty familiar with the term but not the concept')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/assets/img/blog-detail-image.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('How Growth Marketing Impact The Businesses In 2020')
				SetTwitterDescription('Over the last few years, the term growth marketing has attained wider popularity and undoubtedly gained a heightened growth. Well, many of us might be pretty familiar with the term but not the concept')
				SetTwitterImage('https://www.eziosolutions.com/assets/img/blog-detail-image.png')
				break;
			case '/blog/think-big-but-start-small':
				SetTitle('Think Big But Start Small')
				SetKeywords("Social Media Optimization, SMO Services, Facebook Digital Marketing, Twitter Marketing Company, Linkedin Marketing Agency, Youtube Video Promotion Services, Pinterest Marketing, Snapchat Marketing Services, SMO Strategy")
				SetDescription("Over the last few years, the term growth marketing has attained wider popularity and undoubtedly gained a heightened growth. Well, many of us might be pretty familiar with the term but not the concept")
				SetCanonical('https://www.eziosolutions.com/blog/impact-of-growth-marketing-in-businesses')
				SetOgTitle('Think Big But Start Small')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/blog/impact-of-growth-marketing-in-businesses')
				SetOgDescription('Over the last few years, the term growth marketing has attained wider popularity and undoubtedly gained a heightened growth. Well, many of us might be pretty familiar with the term but not the concept')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/assets/img/blog-detail-image.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Think Big But Start Small')
				SetTwitterDescription('Over the last few years, the term growth marketing has attained wider popularity and undoubtedly gained a heightened growth. Well, many of us might be pretty familiar with the term but not the concept')
				SetTwitterImage('https://www.eziosolutions.com/assets/img/blog-detail-image.png')
				break;
			case '/contact-us':
				SetTitle('Digital Marketing Agency | Web Development Company - Contact')
				SetKeywords("Best Digital Marketing Agency ,E-Commerce Website Development Company,Growth Marketing Company,SMO services,PPC advertising,Email marketing Services")
				SetDescription("We are the First Growth Marketing Company in Coimbatore. We do Website Development and all Digital Marketing services  Contact - EZIO Solutions Private Limited")
				SetCanonical('https://www.eziosolutions.com/contact-us')
				SetOgTitle('Digital Marketing Agency | Web Development Company - Contact')
				SetOgSite_name('EZIO Solutions')
				SetOgUrl('https://www.eziosolutions.com/contact-us')
				SetOgDescription('We are the First Growth Marketing Company in Coimbatore. We do Website Development and all Digital Marketing services  Contact - EZIO Solutions Private Limited')
				SetOgType('website')
				SetOgImage('https://www.eziosolutions.com/logo.png')
				SetTwitterCard('summary')
				SetTwitterSite('@EzioSolutions')
				SetTwitterTitle('Digital Marketing Agency | Web Development Company - Contact')
				SetTwitterDescription('We are the First Growth Marketing Company in Coimbatore. We do Website Development and all Digital Marketing services  Contact - EZIO Solutions Private Limited')
				SetTwitterImage('https://www.eziosolutions.com/logo.png')
				break;
			default:
				return null;
		}
	}

	// const isLoggined = () => {

	// 	Isloggedin(true)
	// 	//props.history.push('/dashboard')
	// }
	// const isLoggedOut = () => {
	// 	localStorage.setItem("logged", false);
	// 	Isloggedin(false)
	// }

	let data = {
		"@context": "https://schema.org",

		"@type": "Organization",

		"name": "Ezio Solutions",

		"legalName": "Ezio Solutions Private Limited",

		"url": "https://eziosolutions.com/",

		"logo": " https://www.eziosolutions.com/logo.png",

		"address": {

			"@type": "PostalAddress",

			"streetAddress": " #33/1, 2nd Floor, Kathir Avenue,",

			"addressLocality": " Aandal Street, Coimbatore,",

			"addressRegion": "Tamil Nadu",

			"postalCode": "641004",

			"addressCountry": "India"

		},

		"contactPoint": {

			"@type": "ContactPoint",

			"contactType": "customer support",

			"telephone": "+91 7530093686",

			"email": "connect@eziosolutions.com"

		},

		"sameAs": [

			"https://www.facebook.com/EzioSolutions/",

			"https://www.instagram.com/eziosolutions/",

			"https://twitter.com/EzioSolutions",

			"https://www.linkedin.com/company/ezio-solutions/",

		]
	};

	return (
		<div className="eziowebsite">
			<MetaTags>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={keywords} />
				<link rel="canonical" href={canonical} />
				<meta property="og:title" content={ogtitle} />
				<meta property="og:site_name" content={ogsite_name} />
				<meta property="og:url" content={ogurl} />
				<meta property="og:description" content={ogdescription} />
				<meta property="og:type" content={ogtype} />
				<meta property="og:image" content={ogimage} />
				<meta name="twitter:card" content={twittercard} />
				<meta name="twitter:site" content={twittersite} />
				<meta name="twitter:title" content={twittertitle} />
				<meta name="twitter:description" content={twitterdescription} />
				<meta name="twitter:image" content={twitterimage} />
				<script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
			</MetaTags>
			<React.Suspense fallback={loading}>
			  <AuthProvider>

				<Switch>
					<Route path="/dashboard/smo/linkedin" component={LinkedInPopUp} />
					{
						props.location.pathname.split('/')[1] === 'login' ?
							<Route path="/login" component={DashboardLogIn} /> :
							props.location.pathname.split('/')[1] === 'reset-password' ?
								<Route path="/reset-password" component={ResetPassword} /> :
								props.location.pathname.split('/')[1] === 'changepassword' ?
									<Route path="/changepassword" component={ChangePassword} /> :
									props.location.pathname.split('/')[1] === 'signup' ? <Route path="/signup" component={SignUp} /> :
										props.location.pathname.split('/')[1] === 'dashboard' ?
											<Route path="/dashboard" name="dashboard" render={props => <ClientLayout {...props} />} /> :
											<Route path="/" name="Home" render={props => <SiteLayout {...props} />} />

					}
				</Switch>
			  </AuthProvider>

			</React.Suspense>
		</div>
	);
}

export default withRouter(App);

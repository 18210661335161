import React  , {useState }from 'react';
//import googleicon from '../images/googleicon.png';
import signup_bg from '../images/resetpassword.png';
import site_logo from '../images/ezionew_logo.png';
//import portfolio from '../images/portfolio.png';
//import view from '../images/view.png';
import left_arrow from '../images/left-arrow.png';
import axios from 'axios';
//import { withRouter } from 'react-router'
import {
    Link,
  } from "react-router-dom";

const ResetPassword = (props) =>{
	
	const [fields, Getfields] = useState({});	
	const [errors, Geterrors] = useState({});
	const [name, Getname] = useState({});
	const [Message, ShowMessage] = useState(false);
	
	
	
	const handleChange =(e) =>{
	  fields[e.target.name] = e.target.value;
      Getfields(fields)
	}
	
	 const  validateLogin = () =>{
		  let errors = {};
		  let formIsValid = true;
		  if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter your Email.";
		  }
		  
		  Geterrors(errors)       
		  return formIsValid;
    }
	const resetPassword = (e) =>{
		 e.preventDefault();
		 if(validateLogin()){
		 	console.log(fields);
			 const formdata = new FormData();
			 formdata.append('referer', 'ezioaws');
			 formdata.append('action', 'forgotpassword');
			 formdata.append('email', fields.email);
			 axios.post(`https://demoapi.eziosolutions.com/ezio-api/admin/registration-api.php` , 
				formdata 
			 ).then((response)=>{
			 	ShowMessage(true)
				 console.log(response.data['username'])
				 Getname(response.data)
			 }).catch((error) =>{
				 console.log(error)
			 })
		 }
		 
	}
	
	return(
       <div className="signup-banner">
           <div className="container signup-container">
                <div className="row align-items-end">
                    <div className="col-md-6 col-lg-5 offset-lg-1">
                        <form className="signup-form resetLink" onSubmit={e => resetPassword(e)}>
                        <div className="my-5 al_setpropsal">
                                <Link to='/'><span  className="login-top"><img src={left_arrow} alt="Left Arrow"/>&nbsp;&nbsp;Return to LOGIN</span></Link> 
                               {/* <Link to='/'> <span style={{marginLeft: '25px'}} className="proposal"><img src={portfolio}/>&nbsp;&nbsp;&nbsp;&nbsp;Get a Strategy & Proposal</span></Link> */}
                            </div>
                            <div className="my-3" style={{marginTop: '20px' , 'text-align':'center'}} >
                                <img src={site_logo} alt="Site Logo"/>
                                <h2  className="login-heading">Ezio's AI Powered Access For Your Business <br /> Growth</h2>
								<p className='reset_note'>Please Enter your Email</p>
								{Message ? 
									<div>
										{Object.keys(name).length > 0 ?
										<p className='scuccess_msg'>
										<span>Hello {name.username}..! Reset link has been sent to {name.user_email}</span>
										<span>if you did'nt recieve your reset link , please check your junk/spam email folder</span> 
										</p> :null}
										</div>
										:null}
									</div>
                                                      
						    <input type="email" id="mailid" name='email' className="form-control mb-4" placeholder="Email" onChange={e => handleChange(e)}  />
							<div className="errorMsg">{errors.email}</div>
                            <button className="btn btn-info btn-block my-4 resetbutton bluebtn" type="submit">Send Password Reset Link</button>
                            <div className="links" style={{"text-align":"center"}}>For Support contact us at <a href="mailto:contact@eziosolutions.com">contact@eziosolutions.com</a></div>
                        </form>
                    </div>
                    <div className="col-md-6 image-col">
                        <img src={signup_bg} alt="Signup Bg"/>
                    </div>
                </div>
        </div>
       </div>
	)
}

export default ResetPassword;

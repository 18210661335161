import React, { createContext, useContext, useState ,useEffect} from 'react';
import { useHistory } from 'react-router'

const AuthContext = createContext();

const AdminNew = localStorage.getItem('accessNewToken')
// const historyRoute = useHistory();

// const parseJwt = (token) => {
//    if(token){
// return JSON?.parse(Buffer?.from(token?.split(".")[1], "base64")?.toString());
//   }
//   else{
//     historyRoute.push('/login');
//   }
	
// };
const parseJwt = (token) => {
  if (!token) {
    // Handle the case when the token is not provided
    return null;
  }

  const tokenParts = token.split(".");
  if (tokenParts.length !== 3) {
    // Handle the case when the token doesn't have the expected format
    return null;
  }

  try {
    // Attempt to parse the token
    const decodedToken = JSON.parse(Buffer.from(tokenParts[1], "base64").toString());
    return decodedToken;
  } catch (error) {
    console.error("Error parsing JWT:", error);
    return null;
  }
};

// const AdminNew = 1 ;

// console.log("AdminNew", parseJwt(AdminNew))


export const AuthProvider = ({ children }) => {
  // const idVariable = AdminNew ;
  // console.log("AdminNew", idVariable)
    // const idVariable = parseJwt(AdminNew).id;
    let idVariable;

try {
  const decodedToken = parseJwt(AdminNew);

  if (decodedToken && decodedToken.id) {
    idVariable = decodedToken.id;
  } else {
    // Handle the case when 'id' is not present in the decoded token
    console.error("Error: 'id' not present in the decoded token.");
  }
} catch (error) {
  // Handle the case when there is an error decoding the token
  console.error("Error decoding the token:", error);
}
    const [userId, setUserId] = useState(idVariable);
    const [sernameNew, setSernameNew] = useState(null);

    const updateSernameNew = (newSername) => {
      setSernameNew(newSername);
    };

    useEffect(() => {
      // Update userId when AdminNew changes
      const decodedId = AdminNew ? parseJwt(AdminNew).id : null;
      setUserId(decodedId);
      console.log(sernameNew,"757575")
    }, [AdminNew]);

  const setAuthUserId = (id) => { 
    setUserId(id);
  };

  return (
    <AuthContext.Provider value={{ userId, setAuthUserId }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

 
//  const facebookAppId_insta = "377419840784152";
const facebookAppId_insta = "1269233730241353";

export default function initInstagramSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function () {
            window.FB.init({
                // appId: facebookAppId,
                appId: facebookAppId_insta,
                cookie: true,
                xfbml: true,
                version: 'v11.0'
            });
        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    });
}
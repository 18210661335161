
const Filter = (state = {}, action) => {
  switch (action.type) {
    case 'GET_TOKEN':
      return {
        ...state,
        token: action.payload
      }
    case "GET_PROFILE":
      return {
        ...state,
        profile: action.payload,
      }
    case "PLAN_DETAIL":
      return {
        ...state,
        plandetail: action.payload,
      }
    case "GET_USER_PROFILE":
      let val = {
        ...state,
        planName: action.planName,
        planStatus: action.planStatus,
        personId: action.personId,
        personEmail: action.personEmail,
        roleId: action.roleId,
        personName: action.personName,
        serviceId: action.serviceId,
        passwordStatus: action.passwordStatus,
        questionnaireStatus: action.questionnaireStatus,
        executiveId: action.executiveId,
        no_of_searches: action.no_of_searches,
        customer_id: action.customer_id,
        sub_id: action.sub_id,
        service_list: action.service_list,
      }
      return val
    case 'SET_LOGEMAIL':
      return {
        ...state,
        logemail: action.logemail
      }
    case 'SET_QUESTIONAIRESTATUS':
      return {
        ...state,
        questionnaireStatus: action.questionnaireStatus
      }
    case "GET_PAYMENT_CUSTOMER":
      return {
        ...state,
        customerdetails: action.payload
      }
    case 'SET_PAYMENT_DETAILS':
      return {
        ...state,
        strsessionId: action.strsessionId,
        udetails: action.udetails,
      }
    case 'SET_WALLET_PAYMENT_DETAILS':
      return {
        ...state,
        walletpayment: action.udetails,
      }
    case 'SET_ORDER_DATA':
      return {
        ...state,
        orderData: action.orderData,
      }
    case 'SET_EXECUTIVE_DETAIL':
      return {
        ...state,
        execDetails: action.execDetails,
      }

    case "GET_NOTIFICATION":
      let value = {
        ...state,
        notification: action.payload,
      }
      return value

    case "SET_CLIENT_KEYWORDS":
      return {
        ...state,
        clientApprovedCount: action.clientApprovedCount,
        clientKeywordsCount: action.clientKeywordsCount,
        clientAgreeStatus: action.clientAgreeStatus,
      }
    case "SET_KEYWORD_ID":
      return {
        ...state,
        keywordId: action.keywordId,
      }
    case "SET_BUSINESS_NAME":
      let val01 = {
        ...state,
        businessName: action.businessName,
      }
      return val01
    case "SET_KEYWORD_COUNT":
      return {
        ...state,
        keywordCount: action.keywordCount,
      }
    case "SET_CLIENT_ID":
      return {
        ...state,
        cltbxid: action.cltbxid,

      }
    case "SET_SERVICE_ID":
      return {
        ...state,
        srvbxid: action.srvbxid,
      }
    case "SET_CLIENT_SERVICE_ID":
      return {
        ...state,
        client_service_id: action.client_service_id,
      }
    case "GET_PLAN_ID":
      return {
        ...state,
        plan_id: action.payload,
      }

    //SEO
    case "SET_ABOUT_RESULT_FIRST":
      return {
        ...state,
        about_result_first: action.payload,
      }
    case "SET_ABOUT_RESULT_LAST":
      return {
        ...state,
        about_result_last: action.payload,
      }
    case "SEO_SEARCH_URL":
      return {
        ...state,
        seo_search_url: action.payload,
      }

    //SMO starts
    //SMO-Facebook
    case "GET_Facebook_PAGES":
      let fb_pages = {
        ...state,
        fbpages: action.payload,
      }

      return fb_pages
    case "GET_Facebook_PAGE_DETAIL":
      let fb_page_detail = {
        ...state,
        pagedetail: action.payload,
      }

      return fb_page_detail
    case "GET_Facebook_PAGE_INSIGHTS":
      let fb_page_insights = {
        ...state,
        pageinsights: action.payload,
      }
      return fb_page_insights
    case "GET_Facebook_PAGE_FEEDS":
      let fb_page_feeds = {
        ...state,
        pagefeeds: action.payload,
      }
      return fb_page_feeds

    case "GET_Facebook_ACTIVE_PAGEID":
      let fbPage_id = {
        ...state,
        Page_id: action.payload,
      }
      return fbPage_id
    case "GET_Facebook_Likescount_Bycountry":
      let countrylikes = {
        ...state,
        country_likes: action.payload,
      }
      return countrylikes
    case "GET_SMO_TOASTER":
      let toaster = {
        ...state,
        toaster: action.payload,
      }
      return toaster
    case "GET_SMO_ERROR_TOASTER":
      return {
        ...state,
        toaster_error: action.payload,
      }
    case "GET_SHEDULED_POST_LIST":
      let fbsheduledpostlist = {
        ...state,
        fbsheduledpostlist: action.payload,
      }
      return fbsheduledpostlist

    case "GET_LIKES_COMMENTS_LIST":
      let fblikescommentslist = {
        ...state,
        fblikescommentslist: action.payload,
      }
      return fblikescommentslist
    case "GET_AD_ACCOUNTS_LIST":
      let adaccountslist = {
        ...state,
        adaccountslist: action.payload,
      }
      return adaccountslist
    case "GET_AD_CAMPAIGHN_LIST":
      let adcampaighnlist = {
        ...state,
        adcampaighnlist: action.payload,
      }
      return adcampaighnlist
    case "GET_AD_DETAILS":
      let addetails = {
        ...state,
        addetails: action.payload,
      }
      return addetails

    case "GET_LIKES":
      let likes = {
        ...state,
        likes: action.payload,
      }
      return likes
    case "GET_COMMENTS_ID":
      let cmdId = {
        ...state,
        cmdId: action.payload,
      }
      return cmdId
    case "GET_POST_POP":
      let postpop = {
        ...state,
        postpop: action.payload,
      }
      return postpop
    case "GET_DRAFTS":
      let drafts = {
        ...state,
        drafts: action.payload,
      }
      return drafts
    case "SHOW_LOADER":
      let showloader = {
        ...state,
        showloader: action.payload,
      }
      return showloader

    //SMO-Linkedin
    case "GET_LINKEDIN_DRAFT":
      return {
        ...state,
        isLinkedinDraftClicked: action.payload
      }
    case "GET_LINKEDIN_SCHEDULER":
      return {
        ...state,
        isLinkedinSchedulerClicked: action.payload
      }
    case "GET_LINKEDIN_ORG_LIST":
      let orglist = {
        ...state,
        orglist: action.payload,
      }
      return orglist
    case "GET_LINKEDIN_USERID":
      let linkedinuserID = {
        ...state,
        linkedinuserID: action.payload,
      }
      return linkedinuserID
    case "GET_LINKEDIN_BIO":
      let linkedinbio = {
        ...state,
        linkedinbio: action.payload,
      }
      return linkedinbio
    case "GET_LINKEDIN_POST_LIST":
      let linkedinpostlist = {
        ...state,
        linkedinpostlist: action.payload,
      }
      return linkedinpostlist
    case 'GET_LINKEDIN_POST_COMMENT_LIKE':
      return {
        ...state,
        linkedinPostArrCom: action.payload
      }
    case 'GET_LINKEDIN_VIDEO_LIST':
      return {
        ...state,
        linkedinVideolist: action.payload
      }
    case 'GET_Linkedin_ORG_ID':
      return {
        ...state,
        orgId: action.payload
      }
    case 'GET_LINKEDIN_PAGE_INSIGHTS':
      return {
        ...state,
        linkedinpageinsights: action.payload
      }
    case 'GET_LINKEDIN_FOLLOWERS_COUNT':
      return {
        ...state,
        linkedinfollowerscount: action.payload
      }
    case 'GET_LINKEDIN_AD_ACCOUNTS':
      return {
        ...state,
        adaccounts: action.payload
      }
    case 'GET_LINKEDIN_CAMP_GROUPS':
      return {
        ...state,
        campgrps: action.payload
      }
    case 'GET_LINKEDIN_CAMP_LIST':
      return {
        ...state,
        camplist: action.payload
      }
    case 'GET_LINKEDIN_ADS_INFO':
      return {
        ...state,
        adsinfo: action.payload
      }
    case 'GET_LINKEDIN_EDIT_DRAFT':
      return {
        ...state,
        editDraftData: action.payload,
        type: 'image'
      }
    case 'GET_LINKEDIN_SCHEDULE_POST':
      return {
        ...state,
        schedulepost: action.payload
      }
    case 'GET_LINKEDIN_IMAGE_AD_INFO':
      return {
        ...state,
        imageadinfo: action.payload.imageadinfo,
        ad_id: action.payload.ad_id
      }
    case 'GET_LINKEDIN_CAROSUEL_AD_INFO':
      return {
        ...state,
        carosueladinfo: action.payload,
        ad_id: action.payload.ad_id
      }
    case 'GET_LINKEDIN_AD_INSIGHTS':
      return {
        ...state,
        ad_insights: action.payload
      }
    //SMO-Instagram
    case "GET_INSTAGRAM_PAGES":
      let In_pages = {
        ...state,
        Instapages: action.payload,
      }

      return In_pages
    case "GET_INSTAGRAM_ACTIVE_PAGEID":
      let instaPage_id = {
        ...state,
        Page_id: action.payload,
      }
      return instaPage_id
    case "GET_INSTARAM_PAGE_DETAIL":
      let insta_page_detail = {
        ...state,
        instapagedetail: action.payload,
      }
      return insta_page_detail
    case "GET_INSTARAM_POST_DETAIL":
      return {
        ...state,
        instapostdetail: action.payload,
      }
    case "GET_INSTARAM_PAGE_INSIHTS":
      return {
        ...state,
        instapageinsights: action.payload,
      }
    case "GET_INSTARAM_COUNTRY_INSIHTS":
      return {
        ...state,
        instacountryinsights: action.payload,
      }
    case "GET_INSTAGRAM_SHEDULED_POST_LIST":
      let instasheduledpostlist = {
        ...state,
        instasheduledpostlist: action.payload,
      }
      return instasheduledpostlist
    case "GET_INSTAGRAM_DRAFT":
      return {
        ...state,
        isInstaDraftClicked: action.payload
      }
    case "GET_INSTAGRAM_SCHEDULE":
      return {
        ...state,
        isInstaScheduleClicked: action.payload
      }
    case "GET_INSTAGRAM_DRAFT_POST_LIST":
      let instdraftlist = {
        ...state,
        instdraftlist: action.payload,
      }
      return instdraftlist
    case 'GET_INSTAGRAM_EDIT_DRAFT':
      return {
        ...state,
        instaeditDraftData: action.payload,
      }
    // Twitter
    case 'TWITTER_LOG':
      return {
        ...state,
        twitterlog: action.payload
      }
    case 'GET_TWITTER_USER_DETAILS':
      return {
        ...state,
        twitteruserdetails: action.payload
      }
    case 'GET_TWITTER_TWEET_DETAILS':
      return {
        ...state,
        twittertweetdetails: action.payload
      }
    case 'GET_TWITTER_TEXT_DRAFT':
      return {
        ...state,
        twittertextDraft: action.payload
      }
    case 'GET_TWITTER_IMAGE_DRAFT':
      return {
        ...state,
        twitterimageDraft: action.payload
      }
    case 'GET_TWITTER_SCHEDULER':
      return {
        ...state,
        twitterscheduler: action.payload
      }
    case 'GET_TWITTER_INSIGHTS':
      return {
        ...state,
        twitterInsights: action.payload
      }
    case "GET_TWITTER_SCHEDULE":
      return {
        ...state,
        istwitterScheduleClicked: action.payload
      }
    case 'SET_LOADER':
      return {
        ...state,
        loading: action.payload
      }
    case 'GET_SEO_TOKEN':
      return {
        ...state,
        seo_token: action.payload
      }
    default:
      return state

  }
}

export default Filter
// 
//changepassword
//
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import './index.css';
import App from './App';
//import { icons } from './assets/icons'
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Provider } from 'react-redux'
import Filter from './reducer'

import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';

const middleware = [thunk];


const store = createStore(Filter , compose(applyMiddleware(...middleware))  )


const stripePromise = loadStripe('pk_test_51Ix76KSJ5DTXskVYnfvlNmUW3tCUrEcGboApoCiZFL8I4ht6hCM2GPof1MglNw1ke0lqrxomVfK92EVVHPTEEovy00hrXPnj7e', {
  stripeAccount: 'acct_1Ix76KSJ5DTXskVY'
});


ReactDOM.hydrate(
  <React.StrictMode>
    <Provider store={store}>
		<Router>
    <Elements stripe={stripePromise}>
		  <App />
    </Elements>
		</Router>
	</Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
serviceWorker.register();

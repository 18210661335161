import React, {useState} from 'react';
import googleicon from '../images/googleicon.png';
import signup_bg from '../images/signup_bg.png';
import site_logo from '../images/site-logo.png';
import axios from 'axios';
import { withRouter } from 'react-router'
import portfolio from '../images/portfolio.png';
import view from '../images/view.png';
import left_arrow from '../images/left-arrow.png';
import {
    Link,
  } from "react-router-dom";

const SignUp = (props) =>{
	 
	const [fields, Getfields] = useState({});	
	const [errors, Geterrors] = useState({});
    const [mblshow, Getmblshow] = useState(false)
	
	const handleChange = (e) => {
      
	  fields[e.target.name] = e.target.value;
	  
	  console.log(fields)
      Getfields(fields)
    }
	
	
	 const  validateSignup = () =>{
		  let errors = {};
		  let formIsValid = true;
		  if (!fields["name"]) {
			formIsValid = false;
			errors["name"] = "*Please enter your Name.";
		  }
		  if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter your Email.";
		  }
		  if (!fields["passwords"]) {
			formIsValid = false;
			errors["passwords"] = "*Please enter your passwords.";
		  }
		  Geterrors(errors)       
		  return formIsValid;
    }
	
	const changePass = (e) => {
		console.log(e)
		if(e===1){
		 Getmblshow(false)
		}else if(e===2){
			Getmblshow(true)
		}
    }
	
	const SignUp = (e) =>{
		 e.preventDefault();
		 if(validateSignup()){
		 	
			 const formdata = new FormData();
			 formdata.append('referer', 'ezioaws');
			 formdata.append('action', 'signup');
			 formdata.append('name', fields.name);
			 formdata.append('email', fields.email);
			 formdata.append('password', fields.passwords);
			 axios.post(`https://demoapi.eziosolutions.com/ezio-api/admin/registration-api.php` , 
				formdata 
			 ).then((response)=>{
				  fields["name"] = "";
				  fields["email"] = "";
				  fields["passwords"] = "";
				  props.history.push('/login')
			 }).catch((error) =>{
				 console.log(error)
			 })
		 }
		
	}
	return(
       <div className="signup-banner">
           <div className="container signup-container">
                <div className="row align-items-end">
                    <div className="col-md-6 col-lg-5 offset-lg-1">
                    
						<div className='alg_set'>
						<div className="my-5 al_setpropsal">
                                <Link to='/'><span  className="login-top"><img src={left_arrow} alt="Left Arrow"/>&nbsp;&nbsp;Return to EZIO.COM</span></Link> 
                               <Link to='/contact'> <span style={{marginLeft: '25px'}} className="proposal"><img src={portfolio} alt="portfolio"/>&nbsp;&nbsp;&nbsp;&nbsp;Get a Strategy & Proposal</span></Link>
                            </div>

							<div className="my-3 text-center">
                                <img src={site_logo} alt="siteLogo"/>
                            </div>
                                <h2  className="login-heading">Ezio's AI Powered Access For <br />Your Business Growth</h2>
                            <button   className="btn btn-outline-secondary my-4 d-flex justify-content-between gsingup"><img src={googleicon} alt="GoogleIcon"/><span>Signup with google</span></button>
							<div className="my-4 line" >or</div>
							</div>
							<form className="signup-form" onSubmit={e => SignUp(e)}>
                           
                            
                            <input type="name" id="defaultLoginFormName" className="form-control " placeholder="Name" name='name' onChange={e => handleChange(e)} />
							<div className="errorMsg">{errors.name}</div>                                  
						   <input type="email" id="defaultLoginFormEmail" className="form-control" placeholder="Email" name='email' onChange={e => handleChange(e)} />
                            <div className="errorMsg">{errors.email}</div>
                             {mblshow ?      
                            <div><input type="password" id="defaultLoginFormPassword" name='passwords' className="form-control" placeholder="Password" onChange={e => handleChange(e)}  /><span className="errspan signup-eye" onClick={e => changePass(1)}><img src={view} alt="view"/></span></div>
                            :
                            <div><input type="text" id="defaultLoginFormPassword" name='passwords' className="form-control" placeholder="Password" onChange={e => handleChange(e)}  /><span className="errspan signup-eye" onClick={e => changePass(2)}><img src={view} alt="view"/></span></div>
                        }      
                            <div className="errorMsg">{errors.passwords}</div>
							<button className="btn btn-info btn-block my-4 bluebtn" type="submit">Signup</button>
                            <div className="text-center links">Have an account? <Link to="/login">Login</Link></div>
                            <div className="text-center links">By creating a Ezio account to agree out terms of <br /><a href="">Service</a> and <a href="">Privacy policy</a></div>
                        </form>
                    </div>
                    <div className="col-md-6 image-col">
                        <img src={signup_bg} alt="signup Bg"/>
                    </div>
                </div>
        </div>
       </div>
	)
}

export default withRouter(SignUp);
import React, { useState, useEffect, useRef, } from 'react';
// import googleicon from '../images/googleicon.png';
//import portfolio from '../images/portfolio.png';
import view from '../images/view.png';
import signup_bg from '../images/signup_bg.png';
import left_arrow from '../images/left-arrow.png';
import site_logo from '../images/ezionew_logo.png';
// import { GoogleLogin, GoogleLogout } from 'react-google-login';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from 'axios';
import { useHistory, withRouter } from 'react-router'
import $ from 'jquery';
import OtpTimer from 'otp-timer'
import { TextField, Button } from '@material-ui/core'
import CustomModal from './dashboard-new/components/CustomModal'
import { useSelector, useDispatch } from 'react-redux'
import {
	Link,
	useLocation
} from "react-router-dom";
import Cookies from 'js-cookie'

const LogIn = (props) => {

	let query = useQuery();

	// const CLIENT_ID = '156781662536-4t69gig0r1ivehul4ogd8hi6kbq69jla.apps.googleusercontent.com';
	// const secretId ='Um3IwVsflmd4D6aTXdwwbwUw'

	//	const [accecssToken, GetToken] = useState('');
	const dispatch = useDispatch()
	const reduxValue = useSelector(state => state)
	//  const state = useState()
	const [fields] = useState({});
	const [errors, Geterrors] = useState({});
	const [mblshow, Getmblshow] = useState(false);
	const [emailVal, setEmailVal] = useState('');
	const [passVal, setPassVal] = useState('');
	const [open, setOpen] = useState(false);
	const [loginOtp, setloginOtp] = useState('');
	const [openSuccess, setOpenSuccess] = useState(false)
	const [timer, setTimer] = useState(false)
	const [resendCount, setResendCount] = useState(0)
	const [alertMessage, SetAlertMessage] = useState("Please enter your OTP here")
	const [checked, setChecked] = useState(false)
	const [otpPopup, setOtpPopup] = useState(false)
	const [mobileconfirmotp, setmobileconfirmotp] = useState(false)
	const [emailconfirmotp, setemailconfirmotp] = useState(false)
	const [logotp, setlogotp] = useState()
	const [verifyMobile, setverifyMobile] = useState()
	const [anotherMethod, ShowAnotherMethod] = useState(false)


	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}


	useEffect(() => {
		// alert(Cookies.get('session'))
		localStorage.clear()
		Getmblshow(true)
		dispatch({
			type: 'SET_LOGEMAIL',
			logemail: query.get("email")
		})

		setTimeout(() => {
			$('.resend_otp span').css({
				"position": "absolute",
				"top": "auto",
				"right": "0",
				"bottom": "61px",
			})
			$('.MuiButton-root').addClass('bu_set')
		}, 200)

		if (Cookies.get('email') !== undefined && Cookies.get('password') !== undefined) {
			setEmailVal(Cookies.get('email'))
			setPassVal(Cookies.get('password'))
			document.getElementById('defaultLoginFormPassword').value = Cookies.get('password')
			fields['email'] = Cookies.get('email')
			fields['passwords'] = Cookies.get('password')
		}
	}, [])

	const redirectLogin = (email, password) => {

		const formdata = new FormData();
		formdata.append('referer', 'ezioaws')
		formdata.append('action', 'checklogin')
		formdata.append('email', email)
		formdata.append('password', password)

		axios
			.post(`https://demoapi.eziosolutions.com/ezio-api/admin/login.php`, formdata)
			.then(res => {

				if (res.data.status === 'success') {
					localStorage.setItem('logged', true);
					const values = {}
					if (res.data.userdata[0].role_id === '3') {
						values['planName'] = res.data.userdata[0].plan_name;
						values['planStatus'] = res.data.userdata[0].plan_status
					}
					dispatch({
						type: 'GET_USER_PROFILE',
						...values,
						personId: res.data.userdata[0].id,
						personEmail: res.data.userdata[0].email,
						roleId: res.data.userdata[0].role_id,
						personName: res.data.userdata[0].username,
						serviceId: res.data.userdata[0].service_id,
						passwordStatus: res.data.userdata[0].password_status,
						questionnaireStatus: res.data.userdata[0].ques_status,
						executiveId: res.data.userdata[0].executive_id,
						planStatus: res.data.userdata[0].plan_status,
						no_of_searches: res.data.userdata[0].no_of_searches,
						customer_id: res.data.userdata[0].customer_id,
						sub_id: res.data.userdata[0].subscription_id,
					})
					localStorage.setItem('personId', res.data.userdata[0].id)
					
					dispatch({ 'type': 'GET_NOTIFICATION', payload: res.data.nfcount })

					historyRoute.push('/dashboard/');
				} else if (res.data.status === 'failed' && res.data.status_code === "verify") {
					setOpen(true)
					setTimer(true)

				} else if (res.data.status === 'failed' && res.data.status_code === "limit exceeded") {
					otpErrRef.current.style.display = 'block'
					setTimeout(() => {
						otpErrRef.current.style.display = 'none'
					}, 3000)
				}
				else {
					localStorage.setItem('logged', false);
					loginErrRef.current.style.display = 'block'
					setTimeout(() => {
						loginErrRef.current.style.display = 'none'
					}, 3000)
				}
			})
			.catch(err => {
				console.log(err);
			})

	}



	// const LoginWithGmail =(response) =>{
	// 	console.log(response)
	// 	 if(response.accessToken){
	// 	 	const profile =response.profileObj;
	// 	 	console.log(response.profileObj,"profileObj");
	// 		const formdata = new FormData();
	// 		 formdata.append('referer', 'ezioaws');
	// 		 formdata.append('action', 'signinwithgoogle');
	// 		 formdata.append('email', profile.email);
	// 		 formdata.append('name', profile.name);
	// 		 axios.post(`https://demoapi.eziosolutions.com/ezio-api/admin/registration-api.php` , 
	// 			formdata 
	// 		 ).then((response)=>{
	// 		 		if(response.data.status===false){
	// 		 		console.log(response,"hai")
	// 		 	}else{
	// 				console.log(response)
	// 				localStorage.setItem("logged", true);
	// 				localStorage.setItem("id", response.data[0]['id']);
	// 				localStorage.setItem("username", response.data[0]['username']);
	// 				localStorage.setItem("email", response.data[0]['user_email']);
	// 				// props.isLoggined();
	// 				props.history.push('/dashboard/home', response.data)
	// 		 	}
	// 		 }).catch((error) =>{
	// 			 console.log(error)
	// 		 })

	// 		GetToken(response.accessToken)
	// 	}
	// }
	// const handleLoginFailure = (response) =>{
	// 	console.log(response)
	// }

	const handleOtp = (e) => {
		setloginOtp(e.target.value)
	}

	const handleEmailChange = (e) => {
		console.log(typeof (e.target.name), typeof (e.target.value));
		fields[e.target.name] = e.target.value;
		setEmailVal(e.target.value);
	}
	const handlePassChange = (e) => {
		fields[e.target.name] = e.target.value;
		setPassVal(e.target.value);
	}

	const changePass = (e) => {
		console.log(e)
		if (e === 1) {
			Getmblshow(false)
		} else if (e === 2) {
			Getmblshow(true)
		}
	}

	const validateLogin = () => {
		let errors = {};
		let formIsValid = true;
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter your Email.";
		}
		if (!fields["passwords"]) {
			formIsValid = false;
			errors["passwords"] = "*Please enter your passwords.";
		}
		Geterrors(errors)
		return formIsValid;
	}


	const historyRoute = useHistory();
	const loginErrRef = useRef();
	const otpErrRef = useRef();


	const validateform = () => {
		const errors = {};
		let formIsValid = true;
		if (!loginOtp) {
			formIsValid = false;
			errors.otp = '*Please enter your OTP.';
			setloginOtp('');
		}

		else if (typeof loginOtp !== 'undefined') {
			var pattern = new RegExp(/^[0-9\b]+$/);
			if (!pattern.test(loginOtp)) {
				formIsValid = false;
				errors.otp = '*Please enter only number.';
				setloginOtp('');
			}
			else if (loginOtp.length !== 5) {
				formIsValid = false;
				errors.otp = '*Please enter valid Otp.';
				setloginOtp('');
			}
		}

		Geterrors(errors);
		setTimeout(() => {
			$(".error_show").css('display', 'none')
		}, 2000)

		return formIsValid;
	};


	const submitOtp = (e) => {
		e.preventDefault()
		if (validateform()) {
			e.preventDefault();
			const formdata = new FormData();
			formdata.append('referer', 'ezioaws')
			formdata.append('action', 'check_otp')
			formdata.append('otp', loginOtp)

			axios
				.post(`https://demoapi.eziosolutions.com/ezio-api/admin/login.php`, formdata)
				.then(res => {
					console.log(res.data)
					if (res.data.status === "success") {
						setOpen(false)
						setTimer(true)
						redirectLogin(emailVal, passVal)

						//redirectLogin(emailVal , passVal)
					} else {
						alert('Somthing Wrong')
					}
				});
		}


	}

	const resendOtp = (e) => {
		console.log("resend called")
		e.preventDefault();
		setTimeout(() => {
			$('.resend_otp span').css({
				"position": "absolute",
				"top": "auto",
				"right": "0",
				"bottom": "61px",
			})
		}, 100)

		const formdata = new FormData();
		formdata.append('referer', 'ezioaws')
		formdata.append('action', 'resend_otp')
		formdata.append('email', reduxValue.logemail)

		axios
			.post(`https://demoapi.eziosolutions.com/ezio-api/admin/login.php`, formdata)
			.then(res => {
				setTimer(true)
				if (res.data.status === 'failed' && res.data.status_code === "limit exceeded") {
					$('.resend_otp').css('display', 'none')
				}
			});
	}

	const Loginfunc = () => {
		const formdata = new FormData();
		formdata.append('referer', 'ezioaws')
		formdata.append('action', 'checklogin')
		formdata.append('email', emailVal)
		formdata.append('password', passVal)
		
		axios
			.post(`https://demoapi.eziosolutions.com/ezio-api/admin/login.php`, formdata)
			.then(res => {
				if (res.data.status === 'failed' && res.data.status_code === "verify") {
					setOpen(true)
					setTimer(true)
				}
				else if (res.data.status === 'success') {
					console.log("RESPONSE FROM LOGIN", res.data)
					localStorage.setItem('personId', res.data.userdata[0].id)
					localStorage.setItem('serviceId', res.data.userdata[0].service_id)
					localStorage.setItem('p_Id', res.data.userdata[0].plan_id)
					localStorage.setItem('p_Status', res.data.userdata[0].plan_status)
					dispatch({ type: 'GET_PROFILE', payload: res.data.userdata })
					localStorage.setItem('logged', true);
					console.log("340",res.data,emailVal,passVal)
					const dataLogin = {
						"email": emailVal,
						"password": passVal
					}
					axios
					.post(`https://smo-backend.eziosolutions.com/api/login/`, dataLogin)
					.then(res => {
						console.log("340111",res.data)
						localStorage.setItem('accessNewToken', res.data.access)
					})
					.catch(err=>{
						console.log(err,"err")
					})
					const values = {}
					if (res.data.userdata[0].role_id === '3') {
						values['planName'] = res.data.userdata[0].plan_name;
						values['planStatus'] = res.data.userdata[0].plan_status
					}
					dispatch({
						type: 'GET_USER_PROFILE',
						...values,
						personId: res.data.userdata[0].id,
						personEmail: res.data.userdata[0].email,
						roleId: res.data.userdata[0].role_id,
						personName: res.data.userdata[0].username,
						serviceId: res.data.userdata[0].service_id,
						passwordStatus: res.data.userdata[0].password_status,
						questionnaireStatus: res.data.userdata[0].ques_status,
						executiveId: res.data.userdata[0].executive_id,
						planStatus: res.data.userdata[0].plan_status,
						no_of_searches: res.data.userdata[0].no_of_searches,
						customer_id: res.data.userdata[0].customer_id,
						sub_id: res.data.userdata[0].subscription_id,
						service_list: res.data.service_list
					})

					dispatch({ 'type': 'GET_NOTIFICATION', payload: res.data.nfcount })
					//  setTimeout(()=>{
					// 	window.location.reload();
					//  },1)
					historyRoute.push('/dashboard');

				} else if (res.data.status === 'failed' && res.data.status_code === "limit exceeded") {
					otpErrRef.current.style.display = 'block'
					setTimeout(() => {
						otpErrRef.current.style.display = 'none'
					}, 3000)
				} else {
					localStorage.setItem('logged', false);
					loginErrRef.current.style.display = 'block'
					setTimeout(() => {
						loginErrRef.current.style.display = 'none'
					}, 3000)
				}
			})
			.catch(err => {
				console.log(err);
			})
	}

	const handleloginOtp = (e) => {
		setlogotp(e.target.value)

	}

	const sendOtpAuth = (verificationtype) => {
		const formdata = new FormData();
		formdata.append('referer', 'ezioaws')
		formdata.append('action', 'checkloginotp')
		formdata.append('email', emailVal)
		if (verificationtype == 'mobile') {
			formdata.append('type', 'mobile')
		} else if (verificationtype == 'email') {
			formdata.append('type', 'email')
		} else {
			formdata.append('type', 'both')
		}

		axios.post(`https://demoapi.eziosolutions.com/ezio-api/admin/login.php`, formdata)
			.then(res => {
				console.log(res)
				if (res.data.status == "success") {
					setverifyMobile(res.data.mobile_no.substring(10, 8))
					setOtpPopup(true)
					if (verificationtype == 'mobile') {
						setmobileconfirmotp(false)
					} else {
						setemailconfirmotp(false)
					}

				}
			}).catch(err => {
				console.log(err);
			})
	}

	const checkOtpAuth = () => {
		if (typeof logotp !== 'undefined') {
			const errors = {};
			var pattern = new RegExp(/^[0-9\b]+$/);
			if (!pattern.test(logotp)) {
				errors.otp = '*Please enter only number.';
				$(".error_show").fadeIn()
			}
			else if (logotp.length !== 5) {
				errors.otp = '*Please enter valid Otp.';
				$(".error_show").fadeIn()
			} else {
				const formdata = new FormData();
				formdata.append('referer', 'ezioaws')
				formdata.append('action', 'verify_login_otp')
				formdata.append('otp', logotp)
				axios.post(`https://demoapi.eziosolutions.com/ezio-api/admin/login.php`, formdata)
					.then(res => {
						console.log(res)
						if (res.data.status == "success") {
							Cookies.set('session', emailVal, { expires: 1 })
							errors.success = res.data.message;
							$(".success_show").fadeIn()
							setTimeout(() => {
								Loginfunc()
							}, 2000)
						} else {
							errors.otp = res.data.message;
							$(".error_show").fadeIn()
						}
					}).catch(err => {
						console.log(err);
						errors.otp = err;
						$(".error_show").fadeIn()
					})
			}
			Geterrors(errors)
			setTimeout(() => {
				$(".error_show").fadeOut()
				$(".success_show").fadeOut()
			}, 2000)
		}


	}

	const Login = (e) => {
		e.preventDefault();
		if (checked) {
			Cookies.set('email', emailVal, { expires: 1 })
			Cookies.set('password', passVal, { expires: 1 })
		}


		if (validateLogin()) {
			const formdata = new FormData();
			formdata.append('referer', 'ezioaws');
			formdata.append('action', 'login_otp_status');
			formdata.append('email', emailVal);
			axios.post(`https://demoapi.eziosolutions.com/ezio-api/admin/login.php`,
				formdata
			).then((response) => {
				if (response.data[0].login_otp == 1 && response.data[0].email_otp == 1) {
					setmobileconfirmotp(true)
					ShowAnotherMethod(true)
				} else if (response.data[0].login_otp == 1) {
					setverifyMobile(response.data[0].mobile_no.substring(10, 8))
					setmobileconfirmotp(true)
				} else if (response.data[0].email_otp == 1) {
					setemailconfirmotp(true)
				} else {
					Loginfunc()
				}
			}).catch((error) => {

			})

			//			Loginfunc()
			// if (Cookies.get('session') != undefined) {
			// 	Loginfunc()
			// } else {
			// 	sendOtpAuth()
			// }

		}
	}

	// const ForgotPassword = (e) => {
	// 	 e.preventDefault();
	// 	 const formdata = new FormData();
	// 	 formdata.append('referer', 'ezioaws');
	// 	 formdata.append('action', 'forgotpassword');
	// 	 formdata.append('femail', e.target[1].value);
	// 	 axios.post(`https://demoapi.eziosolutions.com/ezio-api/admin/registration-api.php` , 
	// 		formdata 
	// 	 ).then((response)=>{
	// 		 props.history.push('/login')
	// 	 }).catch((error) =>{
	// 		 console.log(error)
	// 	 })
	// }

	//const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
	//const timeRef=useRef();

	const handleClick = (e) => {
		console.log(e)
		setResendCount(resendCount + 1)
		if (resendCount >= 2) {
			$('.otp-alert').css('color', 'red');
			$('.otp-alert').css("backgroundColor", "rgb(244 67 54 / 19%)");
			$('.otp-alert').css('border', '#ff00008a solid 1px')
			$('.resend_otp').css('display', 'none');
			SetAlertMessage("Oops... You have reached maximum attempt");
		}
		//desired function to be performed on clicking resend button
	}
	const OtpTxtFldStyle = {
		width: "100%",
		margin: "17px 0px 25px 0px"

	}
	const SubmitBtnStyle = {
		backgroundColor: "#143059",
		border: "#143059 solid 2px",
		color: "#fff",
		float: 'right',

	}

	const OtpAlert = {
		color: 'green',
		backgroundColor: "rgb(0 128 0 / 16%)",
		height: '30px',
		borderRadius: '5px',
		padding: "4px",
		border: "#00800085 solid 1px",
		margin: "15px 0px 10px 0px",

	}
	const styling = {
		transition: "all 0.5s"
	}


	const tryMethod = () => {
		setemailconfirmotp(true)
	}

	return (
		<div className="signup-banner">
			<CustomModal open={openSuccess} onClose={() => { setOpenSuccess(false) }} style={styling}>
				<div className="scsmdl-container">
					<div className="scsmdl-header">
						<p>Lorem ipsum odor amet</p>
						<img src={'/modalclose.jpg'} className="scsmdl-closeimg" onClick={() => { setOpenSuccess(false) }} alt="close img" />
					</div>
					<div className="scsmdl-body">
						<img src={'/successmodal.jpg'} className="scsmdl-successimg" alt="success img" />
						<p>
							Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
							dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
						</p>
					</div>
					<button className="scsmdl-closebtn" onClick={() => { setOpenSuccess(false) }}>Close</button>
				</div>

			</CustomModal>

			<CustomModal open={mobileconfirmotp}>
				<div className="scsmdl-container-min-width">
					<div className="login-popup-con">
						<img src={require("../images/login-otp-popup.png")} />
						<h4>Authenticate Your Account</h4>
						<p>Thanks for keeping your Account Secure <br />Check your Mobile Device (xxx-xxxxx{verifyMobile})</p>
						<div className='trymethod'>
							{anotherMethod === true ? <button className="trymethod_button" onClick={e => tryMethod()}>
								Try Another Method
							</button> : null}
							<button onClick={e => sendOtpAuth('mobile')}>
								Send OTP
							</button>
						</div>
					</div>
				</div>
			</CustomModal>

			<CustomModal open={emailconfirmotp}>
				<div className="scsmdl-container-min-width">
					<div className="login-popup-con">
						<img src={require("../images/login-otp-popup.png")} />
						<h4>Authenticate Your Account</h4>
						<p>Thanks for keeping your Account Secure <br />Check your Email ({emailVal})</p>
						<button onClick={e => sendOtpAuth('email')}>
							Send OTP
						</button>

					</div>
				</div>
			</CustomModal>

			<CustomModal open={otpPopup}>
				<div className="scsmdl-container-min-width">
					<div className="login-popup-con">
						<img src={require("../images/login-otp-popup.png")} />
						<h4>Authenticate Your Account</h4>
						<p>Thanks for keeping your Account Secure <br />Check your Mobile Device (xxxxxxxx{verifyMobile})</p>
						<div>
							<label>Your Verification Code</label>
							<input type='text' placeholder="Enter 6 digit code" onChange={e => handleloginOtp(e)} />
							<div className="error_show" style={{ 'color': 'red' }}>{errors.otp}</div>
							<div className="success_show" style={{ 'color': 'green' }}>{errors.success}</div>
						</div>
						{/* <label>
							<input type='checkbox'/><span>Remember this device</span>
						</label> */}
						<button onClick={e => checkOtpAuth()}>
							Submit
						</button>
						<p>Haven't Received it? <span onClick={e => sendOtpAuth('mobile')} style={{ 'cursor': 'pointer' }}>Resend a new code</span></p>
					</div>



				</div>

			</CustomModal>
			<Modal open={open} onClose={onCloseModal} center>
				<div className='col-lg-12' style={{ 'padding': '18px' }}>

					<div style={OtpAlert} className="otp-alert"><p>{alertMessage}</p></div>

					{/* <OtpTimer style={stylingdiv} className="r_button" background="#fff" buttonColor='#202044' textColor="#000" ButtonText='Resen OTP' text='' seconds= {1} minutes={2} resend={handleClick} /> */}

					<form onSubmit={e => submitOtp(e)}>
						<div class="form-group" >
							<TextField id="standard-basic" label="OTP*" style={OtpTxtFldStyle} onChange={handleOtp} />
							{/* <input type="text" name="otp" class="form-control" value={loginOtp} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your OTP"  onChange={handleOtp}/> */}
						</div>
						<Button variant="outlined" type="" style={SubmitBtnStyle} className="otp-submit">Submit</Button>
						<a href='# ' className='resend_otp' onClick={e => resendOtp(e)}><OtpTimer className="r_button" background="#fff" buttonColor='#202044' textColor="#000" ButtonText='RESEND OTP' text='' seconds={59} minutes={1} resend={handleClick} /> </a>
					</form>



					<div className="error_show" style={{ 'color': 'red' }}>{errors.otp}</div>
				</div>

			</Modal>

			<div className="container signup-container">
				<div className="row align-items-end">
					<div className="col-md-6 col-lg-6 offset-lg-1">
						<form className="signup-form" onSubmit={e => Login(e)}>
							<div className="my-4 row">
								<div className="col-xs-12 col-md-5 col-lg-5 nowrap-txt">
									<a href='https://www.eziosolutions.com' target="_blank" rel="noopener noreferrer"><span className="login-top"><img src={left_arrow} alt="" />&nbsp;&nbsp;Return to EZIO.COM</span></a>
								</div>
								{/* <div className="col-xs-12 col-md-7 col-lg-7 mbl-aligncenter">
									<a href='https://www.eziosolutions.com/contact-us'  target="_blank"> <span style={{marginLeft: '25px'}} className="proposal"><img src={portfolio} alt="" />&nbsp;&nbsp;&nbsp;&nbsp;Get a Strategy & Proposal</span></a>
								</div> */}
							</div>
							<div className="my-3" style={{ 'text-align': 'center' , 'width':'150px', 'margin':'0 auto'}}>
								<img src={site_logo} alt="" />
							</div>
							<h2 className="login-heading">Ezio's AI Powered Access For <br /> Your Business Growth</h2>
							<div className="err-msg-common-tab" ref={loginErrRef}>Email or Password entered is incorect!</div>
							<div className="err-msg-common-tab" ref={otpErrRef}>Please Contact Customer Support</div>
							<input type="email" id="defaultLoginFormEmail" name='email' value={emailVal} className="form-control" placeholder="Email" onChange={handleEmailChange} />
							<div className="errorMsg">{errors.email}</div>
							{
								mblshow ?
									<div>
										<input type="password" id="defaultLoginFormPassword" name='passwords' className="form-control" placeholder="Password" onChange={handlePassChange} /><span className="errspan signup-eye" onClick={e => changePass(1)}><img src={view} alt="" /></span>
									</div>
									:
									<div>
										<input type="text" id="defaultLoginFormPassword" name='passwords' value={passVal} className="form-control" placeholder="Password" onChange={handlePassChange} /><span className="errspan signup-eye" onClick={e => changePass(2)}><img src={view} alt="" /></span>
									</div>
							}
							<div className="errorMsg">{errors.passwords}</div>
							<div className="set_checkbox">
								<input id="remember" type="checkbox" name='remember' onClick={() => setChecked(!checked)} checked={checked} /><span className="checkspan">Remember me</span>
							</div>
							<button className="btn btn-info btn-block my-4 bluebtn" type="submit">Login</button>
							<div className="links" style={{ "text-align": "center" }}>Forgot Your Password?  <Link to="/reset-password">Reset</Link></div>
							{  /*<div className="text-center links">Don't have an account yet? <Link to="/signup">Signup for free</Link></div>*/}
						</form>
					</div>
					<div className="col-md-6 col-lg-5 image-col">
						<img src={signup_bg} alt="" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default withRouter(LogIn);
import React  , {useState  }from 'react';
//import googleicon from '../images/googleicon.png';
import signup_bg from '../images/resetpassword.png';
import site_logo from '../images/site-logo.png';
import axios from 'axios';
//import portfolio from '../images/portfolio.png';
//import view from '../images/view.png';
import left_arrow from '../images/left-arrow.png';
//import { withRouter } from 'react-router'
import {
    Link,
} from "react-router-dom";
  
 
const ChangePassword = (props) => {
	console.log(props.match.params.id);
	
	const [fields, Getfields] = useState({});
	const [errors, Geterrors] = useState({});
	const [Message, ShowMessage] = useState(false);
	const [disable, ShowDisable] = useState(true);
	
	const handleChange =(e) =>{
	  fields[e.target.name] = e.target.value;
      Getfields(fields)
	}
	
	
	const  validateLogin = () =>{
		  let errors = {};
		  let formIsValid = true;
		  if (!fields["newpassword"]) {
			formIsValid = false;
			errors["newpassword"] = "*Please enter your New Password.";
		  }
		  if(typeof fields["newpassword"] !== 'undefined'){
			if (fields["newpassword"].length < 8) {
			  errors["newpassword"] ="Your password must be at least 8 characters" 
			}
			if (fields["newpassword"].search(/[a-z]/i) < 0) {
			  errors["newpassword"] = "Your password must contain at least one letter."
			}
			if (fields["newpassword"].search(/[0-9]/) < 0) {
			  errors["newpassword"] = "Your password must contain at least one digit."
			}
			if (fields["newpassword"].search(/[!@#$%^&*]/) < 0) {
			  errors["newpassword"] = "Altealse one Special charecter."
			}
		  }
		   if (!fields["confirmpassword"]) {
			formIsValid = false;
			errors["confirmpassword"] = "*Please enter your Confirm New Password.";
		  }
		  
		  if (fields["newpassword"] !== fields["confirmpassword"]) {
			formIsValid = false;
			errors["confirmpassword"] = "*New Password and Confirm Password not Matched";
		  }
		  
		  
		  
		  Geterrors(errors)       
		  return formIsValid;
    }
	
	
	const resetPassword = (e) =>{
		 e.preventDefault();
		 if(validateLogin()){
		 	console.log(fields);
		 	// return false;
			 const formdata = new FormData();
			 formdata.append('referer', 'ezioaws');
			 formdata.append('action', 'resetpassword');
			 formdata.append('newpass', fields.newpassword);
			 formdata.append('conpass', fields.confirmpassword);
			 formdata.append('id', props.match.params.id);
			 formdata.append('email' , localStorage.getItem('personEmail'))
			 axios.post(`https://demoapi.eziosolutions.com/ezio-api/admin/registration-api.php` , 
				formdata 
			 ).then((response)=>{
			 	ShowMessage(true)
			 	ShowDisable(false)
				 console.log(response)
			 }).catch((error) =>{
				 console.log(error)
			 })
		 }
		 
	}
	
	
	return(
			 <div className="signup-banner">
			   <div className="container signup-container">
					<div className="row align-items-end">
						<div className="col-md-6 col-lg-5 offset-lg-1">
							<form className="signup-form resetLink" onSubmit={e => resetPassword(e)}>
							<div className="my-3" >
							    <Link to='/'><span  className="login-top"><img src={left_arrow} alt="Left Arrow"/>&nbsp;&nbsp;Return to LOGIN</span></Link>
                                {/* <span  className="proposal"><img src={portfolio}/>&nbsp;&nbsp;&nbsp;&nbsp;Get a Strategy & Proposal</span> */}
                            </div>
								<div className="my-3 text-center" style={{marginTop: '20px'}}>
									<img src={site_logo} alt="Site Logo"/>
                                <h2  className="login-heading">Ezio's AI Powered Access For Your Business Growth</h2>
									<p style={{marginTop: '40px'}} className='reset_note'>Reset Your Password</p>
									
								</div>
														  
								<input type="text" id="password" name='newpassword' className="form-control mb-4" placeholder="New Password" onChange={e => handleChange(e)}  />
								<div className="errorMsg">{errors.newpassword}</div>
								<input type="text" id="confirmpassword" name='confirmpassword' className="form-control mb-4" placeholder=" Confirm New Password" onChange={e => handleChange(e)}  />
								<div className="errorMsg">{errors.confirmpassword}</div>
								{disable ?
								<button className="btn btn-info btn-block my-4 resetbutton bluebtn" type="submit">Reset Password</button>
								:
								<button className="btn btn-info btn-block my-4 resetbutton bluebtn" type="button">Reset Password</button>
							}
								<div className="my-3 text-center" style={{marginTop: '20px'}}>
									{Message ? <p className='scuccess_msg'>
										<span>Your password has been changes successfully</span>
										<span style={{color:'#000',fontWeight:'bold'}}> <Link to="/login">Click Here to Login your Account</Link></span>
									</p> :null}
								</div>
								
								<div className="text-center links">For Support contact us at <Link to="#">contact@eziosolutions.com</Link></div>
							</form>
						</div>
						<div className="col-md-6 image-col">
							<img src={signup_bg} alt="Signup Bg"/>
						</div>
					</div>
			</div>
		   </div>
	)
}

export default ChangePassword;